import React from "react"
import { Link } from "gatsby"
import Layout from "../../templates/layout"
import SEO from "../../components/seo/seo"
import Hero from "../../components/hero/hero"
import HaveQuestions from "../../components/haveQuestions/haveQuestions"
import ExpandingCard from "../../components/ExpandingCard/ExpandingCard"
import ExpandAllContainer from "../../components/ExpandingCard/ExpandAllContainer"
import BrandLogo from "../../images/logos/jevtana-logo.svg"
import QuestionsIcon from "../../images/icons/icon-questions-jevtana.svg"
import HeroIcon from "../../images/icons/icon-faq-jevtana-banner.svg"
import PrescribingInfo from "../../components/prescribingInfo/prescribingInfo"
import BackToTop from "../../components/backToTop/BackToTop"


const pageContent = () => (

    <Layout pageid="page-faq" pageClass="patient-jevtana-faq mobile-height-hero">
      <SEO
      title="Patient FAQs for JEVTANA® (cabazitaxel) | Patient Site"
      keywords="CareASSIST, Sanofi, JEVTANA® (cabazitaxel), Patient Site, Frequently Asked Questions"
      description="Find FAQs about CareASSIST by Sanofi, including questions on JEVTANA copay assistance through the CareASSIST Copay Program. See Prescribing Info, including Boxed WARNING, and Patient Info"
      />

      <Hero
      brandLogo={BrandLogo}
      brandAlt="Jevtana Logo"
      headline="Frequently asked questions"
      copy=""
      heroImgClass="hero-icon"
      hasLogo={true}
      hasIcon={true}
      heroIcon={HeroIcon}
      iconAlt="Frequently Asked Questions Icon"
      />
      
      <section className="content-section">
        <main className="white mb-2 jevtana bg-color">
          <ExpandAllContainer btnClass='blue'>
            <ExpandingCard cardClass="jevtana bg-color"
            mainContent={() => (
              <div className="expanding-card-content header">
                <h2>How do I receive financial assistance through CareASSIST?</h2>              
              </div>
            )}
            hiddenContent={() => (
              <div className="expanding-card-content hidden">
                  <p><a id="patient-jevtana-enroll" href="../../pdfs/careassist-program-enrollment-form.pdf" target="_blank" rel="noreferrer">Enroll</a> in CareASSIST with your doctor's office to learn more about financial assistance options. We will use the information in the form to determine your eligibility for financial assistance and additional support options through CareASSIST.</p>
                  <p>You can also check to see if you qualify for copay support by applying directly on our <a id="patient-jevtana-copay-site" href="https://portal.trialcard.com/sanofi/careassist" target="_blank" rel="noreferrer">copay site</a>.</p>
              </div>
            )}
            />
            <ExpandingCard  cardClass="jevtana bg-color"
            mainContent={() => (
              <div className="expanding-card-content header">
                <h2>Are there any eligibility requirements for CareASSIST?</h2>              
              </div>
            )}
            hiddenContent={() => (
              <div className="expanding-card-content hidden">
                  <p>Yes, there are certain eligibility requirements. These will vary based on the financial assistance program you are eligible for. Details about the programs can be found on the <Link id="patient-jevtana-financial-assistance" to="/jevtana/financial-assistance/">Financial Assistance</Link> page. Upon registration, you will receive all program details.</p>
              </div>
            )}
            />
            <ExpandingCard  cardClass="jevtana bg-color"
            mainContent={() => (
              <div className="expanding-card-content header">
                <h2>How will I know if I am eligible for CareASSIST?</h2>              
              </div>
            )}
            hiddenContent={() => (
              <div className="expanding-card-content hidden">
                <p>Once your application is processed, you will receive a letter communicating your approval status within 2 business days. If you are approved for CareASSIST, a dedicated Care Manager will reach out to you by phone to welcome you to the program and discuss your options.</p>
                  <p>If you would like to check to see if you qualify for copay support, you can also do so through our <a id="patient-jevtana-copy-site" href="https://portal.trialcard.com/sanofi/careassist" target="_blank" rel="noreferrer">copay site</a>. You will receive a real-time update on your eligibility.</p>
              </div>
            )}
            />
            <ExpandingCard  cardClass="jevtana bg-color"
            mainContent={() => (
              <div className="expanding-card-content header">
                <h2>What is the difference between the CareASSIST Copay Program and the Patient Assistance Program?</h2>              
              </div>
            )}
            hiddenContent={() => (
              <div className="expanding-card-content hidden">
                <div className="copay-patient-program flex-section column">
                  <div className="copay">
                    <h4>Copay Program</h4>
                    <ul>
                      <li>For commercially insured patients only</li>
                      <li>You may receive up to $25,000 in assistance for product-related copays, coinsurance, and deductibles</li>
                      <li>No income requirement</li>
                    </ul>
                  </div>
                  <div className="patient">
                    <h4>Patient Assistance Program</h4>
                    <ul>
                      <li>For patients who don&rsquo;t have insurance, lack coverage for their prescribed treatment, or are covered under Medicare Part B with no extra coverage</li>
                      <li>You must have an annual household income that does not exceed the greater of $100,000 or 500% of the current Federal Poverty Level</li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
            />
          </ExpandAllContainer>
        </main>
        <p className="pi-paragraph">Please see full <a id="patient-jevtana-pi" href="https://products.sanofi.us/jevtana/jevtana.html" target="_blank" rel="noreferrer">Prescribing Information</a>, including Boxed WARNING, and{" "}
          <a id="patient-jevtana-patient-info"
            target="_blank"
            href="https://products.sanofi.us/Jevtana/Jevtana.html#section-21"
          >
            Patient Information
          </a>.
        </p>
        <HaveQuestions 
        icon={QuestionsIcon}
        />
      </section>
      <div className="back-to-top-container">      
      <BackToTop />
    </div>

    </Layout>

)

export default pageContent